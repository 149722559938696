import React from 'react';
import './App.css';
import { Demokoffer } from './component/Demokoffer';
import buildingx from './img/buildingx_dark.png'
import { useAuth0 } from "@auth0/auth0-react";

export type sensorValues = {
  airqual: { value: number, unit: string, timestamp: Date },
  humidity: { value: number, unit: string, timestamp: Date },
  window: { left: string, right: string, timestamp: Date },
  roomtemp: { value: number, unit: string, timestamp: Date },
  setRoomtemp: { value: number, unit: string, timestamp: Date },
  presence: { value: string, timestamp: Date },
  lastValue: Date,
}

export type machineCredentials = {
  clientId: string
  clientSecret: string,
}

export const defaultDate = new Date("1970-01-01");

export const defaultSensorValues: sensorValues = {
  airqual: { value: 0, unit: 'ppm', timestamp: defaultDate },
  humidity: { value: 0, unit: '%', timestamp: defaultDate },
  window: { left: '', right: '', timestamp: defaultDate },
  roomtemp: { value: 0, unit: '°C', timestamp: defaultDate },
  setRoomtemp: { value: 0, unit: '°C', timestamp: defaultDate },
  presence: { value: '', timestamp: defaultDate },
  lastValue: defaultDate,
}

function App() {
  const { loginWithRedirect, logout, isAuthenticated } = useAuth0();

  return (
    <div className='App'>

        { isAuthenticated  && (<Demokoffer />)}
        { isAuthenticated && (
          <div className="Login">
            <button className="SiemensBtn" id='logoutBtn' onClick={() => {logout( {logoutParams:{returnTo:window.location.origin}})}}>Log Out</button>
          </div>
        )}
        { !isAuthenticated && (
        <div className="card">
          <div className='title'>
            <span>Building in a Box meets </span><span>Building <img style={{ height: "30px" }} src={buildingx} alt="buildingx logo" /></span>
          </div>
          <button className='SiemensBtn' id='loginBtn' onClick={()  => loginWithRedirect()}>Log In</button>
        </div>
      )}
      
    </div>
  );
}

export default App;