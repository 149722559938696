import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

//import { Auth0ProviderWithHistory } from "./component/auth0-provider-with-history";
import { Auth0Provider } from '@auth0/auth0-react';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <Auth0Provider
    domain="dev-demokoffer.eu.auth0.com"
    clientId="6hFsIN3lLhp7oLDltlnNJDzlKKuRAZLC"
    authorizationParams={{
      redirect_uri: window.location.origin,
      audience: "https://demokoffer.360.the-digital-building.com"
    }}
    useRefreshTokens={true}
    cacheLocation="localstorage"
  >
    <App />
  </Auth0Provider>
);
// root.render(
//   <BrowserRouter>
//     <Auth0ProviderWithHistory>
//       <App />
//     </Auth0ProviderWithHistory>
//   </BrowserRouter>
// );