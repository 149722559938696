import React, { useEffect, useState } from 'react';
import { defaultDate } from '../App';
import { APIInteraction } from './APIInteraction';
import './DemokofferHealth.css';
import { useAuth0 } from '@auth0/auth0-react';

export type healthInformation = {
    service: healthService[],
    updatedAt: Date,
    lastValue: Date
}

export type healthService = {
    label: string, 
    status: string, 
    healthy: string
}

export const defaultHealthInformation:healthInformation = {
    service:[],
    updatedAt: new Date("1970-01-01"),
    lastValue: new Date(),
}
const _apiPullInterval:number = 5000;

export function DemokofferHealth(props:any) {
    const [healthInformation, sethealthInformation] = useState<healthInformation>(defaultHealthInformation);
    const [getValueInterval, setValueInterval] = useState<NodeJS.Timer | null>();
    const [hasCallingValues, setHasCallingValues] = useState<boolean>(false);
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();

    async function getValues() {
        let api = new APIInteraction();
        if(isAuthenticated){
            let token = await getAccessTokenSilently();
            api.getHealthDevice(token).then((result) => {if(result){parseSensorValue(result)}});
        }
    }

    function parseSensorValue(result: any) {
        if(typeof result.properties === 'object' && result.properties !== null){
            if(typeof result.properties.containers === 'object' && result.properties.containers !== null){
                
                let healthArray: [any] = result.properties.containers;
                if (healthArray) {
        
                    let servicesArray: healthService[] = [];
                    let updatedAt: Date = defaultDate;

                    if(typeof result.updatedAt === 'string' && result.updatedAt !== null){
                        updatedAt = new Date(result.updatedAt);
                    }else{
                        console.log("Information updatedAt not found in API response");
                    }

                    healthArray.map((value:any) => {
                        servicesArray.push({label:value.name,status:value.state,healthy:value.health})
                    })
                    
                    
                    let demokofferHealthInfo: healthInformation = {updatedAt: updatedAt, service: servicesArray, lastValue: new Date()};

                    setHasCallingValues(true);
                    sethealthInformation(demokofferHealthInfo);
                }
            }else{
                console.log("Information properties.containers not found in API response");
            }
        }
    }

    useEffect(() => {
        setValueInterval(setInterval(() => { getValues() }, _apiPullInterval)); 
    },[])

    return (
        <div className='healthOverlay'>
            <p>Services Health X200 Gateway</p>
            <table>
                <thead>
                    <tr>
                        <th>Service</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {!hasCallingValues && (
                        <tr><td style={{textAlign:"center", fontWeight:"bolder"}} colSpan={2}>Values are being loaded</td></tr>
                    )}
                    {healthInformation.service.map((healthInfo:healthService,index:number) => {
                        let row =  
                        <tr key={index}>
                            <td>{healthInfo.label}</td>
                            <td>
                                <div className='statusCell'>
                                    {
                                        healthInfo.healthy === "STARTING" ? <div className='healthIndicator' style={{backgroundColor:"orange"}}></div>:
                                        healthInfo.healthy === "HEALTHY" ? <div className='healthIndicator' style={{backgroundColor:"green"}}></div>:
                                        <div className='healthIndicator' style={{backgroundColor:"red"}}></div>
                                    }
                                    {healthInfo.healthy}
                                </div>
                            </td>
                        </tr>
                        return row;
                    })}
                </tbody>
            </table>
            
            <div className='Timestamp'>
                Updated at: <span>{healthInformation.updatedAt.toLocaleTimeString('de-de') + " " + healthInformation.updatedAt.toLocaleDateString('de-de')}</span>
                <br />
                Timestamp of last request: <span>{healthInformation.lastValue.toLocaleTimeString('de-de') + " " + healthInformation.lastValue.toLocaleDateString('de-de')}</span>
            </div>
            <div className='closeButton' onClick={() => {if (getValueInterval) { clearInterval(getValueInterval); props.toggleHealth();}}}>X</div>
        </div>
    )
}