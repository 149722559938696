import React, { useEffect, useState } from 'react';
import { defaultSensorValues, sensorValues } from '../App';
import Confetti from 'react-confetti';
import demokoffer from '../img/Demokoffer3.png';
import buildingx from '../img/buildingx_dark.png'
import minus from '../img/Minus.svg';
import plus from '../img/Plus.svg';
import openWindow from '../img/openWindow.png';
import closeWindow from '../img/closeWindow.png';
import emptyDetected from '../img/empty.png';
import fullDetected from '../img/full.png';
import service from '../img/customer-support.png';
import play from '../img/Play.svg';
import pause from '../img/Pause.svg';
import * as echarts from 'echarts';
import { APIInteraction } from './APIInteraction';
import { DemokofferHealth } from './DemokofferHealth';
import { useAuth0 } from '@auth0/auth0-react';

enum SetTempActions{
    Up,
    Down
}
const musicRights:string = "Track rights Circus Theme (Ragtime Version) by Alexander Nakarada | https://www.serpentsoundstudios.com Music promoted by https://www.free-stock-music.com Attribution 4.0 International (CC BY 4.0) https://creativecommons.org/licenses/by/4.0/";
const _apiPullInterval:number = 5000;

type EChartsOption = echarts.EChartsOption;

export function Demokoffer() {
    function autoFontSize() {
        let chartElement = document.getElementById('main');
        if(chartElement){
            let width = chartElement.offsetWidth;
            let newFontSize = Math.round(width / 21);
            if(newFontSize > 12 ){
                newFontSize = 12;
            } else if(newFontSize < 7){
                newFontSize = 7
            }
            return newFontSize;
        }
    };
      
    const gaugeOptions:EChartsOption = {
        series: [
          {
            radius: '100%', 
            type: 'gauge',
            center: ['50%', '55%'],
            min:400,
            max:1300,
            axisLine: {
              lineStyle: {
                width: 10,
                color: [
                  [0.4, '#3dcc5b'],
                  [0.6, '#efd613'],
                  [1, '#ff5454']
                ]
              }
            },
            pointer: {
                itemStyle: {
                    color: 'inherit'
                }
            },
            axisTick: {
              distance: -10,
              length: 0,
              lineStyle: {
                color: '#fff',
                width: 0
              }
            },
            splitLine: {
              distance:-10,
              length: 10,
              lineStyle: {
                color: '#fff',
                width: 2
              }
            },
            axisLabel: {
                color: 'inherit',
                distance: 15,
                fontSize: autoFontSize(),
            },
            detail: {
              valueAnimation: true,
              formatter: '{value} ppm',
              color: 'inherit',
              fontSize:autoFontSize()
            },
            data: [
              {
                value: 0
              }
            ]
          }
        ]
      };
    
    const [kofferValues, setkofferValues] = useState<sensorValues>(defaultSensorValues);
    const [getValueInterval, setValueInterval] = useState<NodeJS.Timer | null>();
    const [isCallingValues, setIsCallingValues] = useState<boolean>(false);
    const [spinnMeRightRound, setSpin] = useState<string>("");
    const [IsSpinning, setSpinning] = useState<boolean>(false);
    const [showHealth, setShowHealth] = useState<boolean>(false);
    const [option, setOption] = useState<EChartsOption>(gaugeOptions);
    const [humidityChart, setHumidityChart] = useState<echarts.ECharts>();
    const [api, setAPI] = useState<APIInteraction>(new APIInteraction());
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();
    const [circusAudio, setAudio] = useState(new Audio("/circus.mp3"))

    async function getValues() {
        if(isAuthenticated){
            let token = await getAccessTokenSilently();
            api.getPoints(token).then((result) => {if(result){parseSensorValue(result)}});
        }
    }

    function changeSetTemp(action:SetTempActions){
        let newValue = kofferValues;
        let newSetTemp = 0;
        if(action === SetTempActions.Up){
            newSetTemp = +kofferValues.setRoomtemp.value + 1;
        }
        if(action === SetTempActions.Down){
            newSetTemp = +kofferValues.setRoomtemp.value - 1; 
        }
        setkofferValues({...newValue, 
            setRoomtemp:{timestamp:new Date(),unit:kofferValues.setRoomtemp.unit,value:newSetTemp}
        });

    }

    function parseSensorValue(result: any) {
        let sensorArray: [any] = result.data;
        if (sensorArray) {
            let setTemp = sensorArray.find((element: any) => { return element.attributes.name === "R'RHvacCoo'SpTRDtr'SpTR" })
            let temp = sensorArray.find((element: any) => { return element.attributes.name === "R'RHvacCoo'RTemp" })
            let airqual = sensorArray.find((element: any) => { return element.attributes.name === "R'RHvacCoo'RAQual" })
            let humidity = sensorArray.find((element: any) => { return element.attributes.name === "R'RHvacCoo'RHuRel" })
            let window = sensorArray.find((element: any) => { return element.attributes.name === "RSegm'WndCont(1)" })
            let window2 = sensorArray.find((element: any) => { return element.attributes.name === "RSegm'WndCont(2)" })
            let presence = sensorArray.find((element: any) => { return element.attributes.name === "R'RHvacCoo'RPscDet" })
            if(setTemp !== undefined && temp !== undefined && airqual !== undefined && humidity!== undefined && window !== undefined && window2 !== undefined && presence !== undefined){
                try {
                    let sensorValuesComb: sensorValues = {
                        airqual: { value: airqual.attributes.pointValue.value, unit: airqual.attributes.systemAttributes.unit, timestamp: new Date(airqual.attributes.pointValue.timestamp) },
                        humidity: { value: humidity.attributes.pointValue.value, unit: humidity.attributes.systemAttributes.unit, timestamp: new Date(humidity.attributes.pointValue.timestamp) },
                        presence: { value: presence.attributes.pointValue.value, timestamp: new Date(presence.attributes.pointValue.timestamp) },
                        roomtemp: { value: temp.attributes.pointValue.value, unit: temp.attributes.systemAttributes.unit, timestamp: new Date(temp.attributes.pointValue.timestamp) },
                        setRoomtemp: { value: setTemp.attributes.pointValue.value, unit: setTemp.attributes.systemAttributes.unit, timestamp: new Date(setTemp.attributes.pointValue.timestamp) },
                        window: { right: window2.attributes.pointValue.value, left: window.attributes.pointValue.value, timestamp: new Date(window.attributes.pointValue.timestamp) },
                        lastValue: new Date(Date.now())
                    }
                    updateGaugediagram(sensorValuesComb);
                    setkofferValues(sensorValuesComb);

                } catch (error) {
                    console.log(error);
                }
                
                
            }else{
                console.log("One of the attributes could not be found in the API response");
            }
            
        }
    }

    const updateGaugediagram = (newValues:sensorValues) => {
        if(humidityChart){
            humidityChart.setOption<echarts.EChartsOption>({
                series: [
                  {
                    data: [
                      {
                        value: +newValues.airqual.value
                      }
                    ]
                  }
                ]
            });
        }
    }
    const toggleHealthOverlay =() =>{
        setShowHealth(false);
        let i = document.getElementById("logoutBtn");
        if (i){
            i.style.pointerEvents = "auto";
        }
    }

   

    useEffect(()=>{ 
        

        var chartDom = document.getElementById('main')!;
        var myChart = echarts.init(chartDom);
        myChart.setOption(option);
        setHumidityChart(myChart);

        myChart.resize();
            myChart.setOption<echarts.EChartsOption>({
                series: [{
                    axisLabel: {
                    fontSize:autoFontSize()
                  },
                  detail:{
                    fontSize:autoFontSize()
                  }
                }]
            })

        window.onresize = function() {
            myChart.resize();
            myChart.setOption<echarts.EChartsOption>({
                series: [{
                    axisLabel: {
                    fontSize:autoFontSize()
                  },
                  detail:{
                    fontSize:autoFontSize()
                  }
                }]
            })
            
        };
    },[])
  

    return (
        <div className='demokofferContainer'>
            {IsSpinning && (<div className='spinInformation' onClick={() => alert(musicRights)} title={musicRights} >Music-Info</div>)}
            {IsSpinning && (<Confetti width={window.innerWidth} height={window.innerHeight} />)}
            <div className='title'>
                <span>Building in a Box meets </span><span>Building <img onClick={() => { if (!IsSpinning) { { setSpin("App-logo-animation"); setSpinning(true);circusAudio.loop = true; circusAudio.play();} } else { setSpin(""); setSpinning(false); circusAudio.pause(); } }} style={{ height: "30px" }} src={buildingx} alt="buildingx logo" /></span>
            </div>
            <div className="parent">
                <div className="demokoffer gridelement"> 
                    <div className='demokofferButtons' style={{position:"relative"}}>
                        <button className='SiemensBtn serviceButtons' id='showServices' onClick={() => {
                            if(showHealth){
                                setShowHealth(false);
                                let i = document.getElementById("logoutBtn");
                                if (i){
                                    i.style.pointerEvents = "auto";
                                }
                            }else{
                                setShowHealth(true);
                                let i = document.getElementById("logoutBtn");
                                if (i){
                                    i.style.pointerEvents = "none";
                                }
                            }
                        }}><img className='buttonIcons' id='serviceImage' src={service}/></button> 
                        {isCallingValues ?
                            (<button className='SiemensBtn serviceButtons' id='dataRetrieval' onClick={() => { if (getValueInterval) { clearInterval(getValueInterval); setIsCallingValues(false); } }}><img className='buttonIcons' src={pause}/></button>) :
                            (<button className='SiemensBtn serviceButtons' id='dataRetrieval' onClick={() => { setValueInterval(setInterval(() => { getValues() }, _apiPullInterval)); setIsCallingValues(true); }}><img className='buttonIcons' src={play}/></button>)
                        }
                        <img src={demokoffer} className={"App-logo " + spinnMeRightRound} alt="logo" />
                    </div>
                </div>
                <div className="airqual gridelement">
                    <div className='valueLabel'>Airquality</div>
                    <div className='valueBox'style={{height:"100%",width:"100%"}} >
                        <div id="main" style={{height:"100%",width:"100%"}} ></div>
                    </div>
                    <div className='valueTimestamp'>{"Timestamp: " + kofferValues.airqual.timestamp.toLocaleTimeString('de-de') + " " + kofferValues.airqual.timestamp.toLocaleDateString('de-de')}</div>
                </div>
                <div className="humidity gridelement">
                    <div className='valueLabel'>Humidity</div>
                    <div className='valueBox'>{kofferValues.humidity.value + " " + kofferValues.humidity.unit}</div>
                    <div className='valueTimestamp'>{"Timestamp: " + kofferValues.humidity.timestamp.toLocaleTimeString('de-de') + " " + kofferValues.humidity.timestamp.toLocaleDateString('de-de')}</div>

                </div>
                <div className="presence gridelement">
                    <div className='valueLabel'>Presence</div>
                    {kofferValues.presence.value === "true" ? <img src={fullDetected} id="fullPresence" className='presenceIcon'/>: <img src={emptyDetected} id="emptyPresence" className='presenceIcon'/>} 
                    <div className='valueTimestamp'>{"Timestamp: " + kofferValues.presence.timestamp.toLocaleTimeString('de-de') + " " + kofferValues.presence.timestamp.toLocaleDateString('de-de')}</div>

                </div>
                <div className="temp gridelement">
                    <div className='valueLabel'>Temperature</div>
                    <div className='valueBox'>{kofferValues.roomtemp.value + " " + kofferValues.roomtemp.unit}</div>
                    <div className='valueTimestamp'>{"Timestamp: " + kofferValues.roomtemp.timestamp.toLocaleTimeString('de-de') + " " + kofferValues.roomtemp.timestamp.toLocaleDateString('de-de')}</div>

                </div>
                <div className="settemp gridelement">
                    <div className='valueLabel'>Set temperature</div>
                    <div className='valueBox'>
                        <img onClick={() => changeSetTemp(SetTempActions.Up)} src={plus} id="plus" className='tempButton'></img>
                        <div>{kofferValues.setRoomtemp.value} {kofferValues.setRoomtemp.unit}</div>
                        <img onClick={() => changeSetTemp(SetTempActions.Down)} src={minus} id="minus" className='tempButton'></img>
                    </div>
                    <div className='valueTimestamp'>{"Timestamp: " + kofferValues.setRoomtemp.timestamp.toLocaleTimeString('de-de') + " " + kofferValues.setRoomtemp.timestamp.toLocaleDateString('de-de')}</div>
                </div>
                <div className="window gridelement">
                    <div className='valueLabel'>Window</div>
                    <div className='valueBox'>
                        {kofferValues.window.left === "true" ? <img src={openWindow} id="openWindowLeft" className='windowIcon'/>: <img src={closeWindow} id="closeWindowLeft" className='windowIcon'/>} 
                        {kofferValues.window.right === "true" ? <img src={openWindow} id="openWindowRight" className='windowIcon'/>: <img src={closeWindow} id="closeWindowRight" className='windowIcon'/>}
                    </div>
                    <div className='valueTimestamp'>{"Timestamp: " + kofferValues.window.timestamp.toLocaleTimeString('de-de') + " " + kofferValues.window.timestamp.toLocaleDateString('de-de')}</div>
                </div>
            </div>
            <div className='Timestamp'>Timestamp of last request <br /><span>{kofferValues.lastValue.toLocaleTimeString('de-de') + " " + kofferValues.lastValue.toLocaleDateString('de-de')}</span></div>
            
            {showHealth &&(
                <DemokofferHealth toggleHealth={toggleHealthOverlay} />
            )}
        </div>
    )
}