import { getFromAPI } from "./utils/data-utils";

const _partition = "db28fff9-bd42-4c4f-9905-87e69e86ff39";
const _deviceId = "f79ac95d-88bf-588b-b61b-9d7028abba22";
const _setPointId= "0f70c76e-076f-531f-9f82-50ccfebe4522";

export class APIInteraction{

  public async setTemp(token:string, newValue:number){
      if(token){
          var myHeaders = new Headers();
          myHeaders.append("accept", "*/*");
          myHeaders.append("Authorization", "Bearer "+token);
          myHeaders.append("Content-Type", "application/vnd.api+json");
          
          var raw = JSON.stringify({
            "data": {
              "type": "Point",
              "id": _setPointId,
              "attributes": {
                "pointValue": {
                  "value": newValue.toString()
                }
              }
            }
          });
          
          var requestOptions:any = {
            method: 'PATCH',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
          };
          
          fetch("https://api.bpcloud.siemens.com/operations/partitions/"+_partition+"/points/"+_setPointId+"?field%5BPoint%5D=pointValue", requestOptions)

      }
  }

  public async getPoints(tokenAPI:string) {

    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/vnd.api+json");
    myHeaders.append("Authorization", "Bearer " + tokenAPI);

    const requestOptions: any = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    try {
      const res:string = await getFromAPI<string>('https://demokoffer.360.the-digital-building.com/api/demokoffer/getPoints',requestOptions)
      return res;

    } catch (error) {
        console.log('Getting Bearer Token failed.');
    }

    
  }
  
  public async getHealthDevice(tokenAPI:string) {

    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/vnd.api+json");
    myHeaders.append("Authorization", "Bearer " + tokenAPI);

    const requestOptions: any = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    try {
      const res:string = await getFromAPI<string>('https://demokoffer.360.the-digital-building.com/api/demokoffer/getHealth',requestOptions)
      console.log(res);
      return res;

    } catch (error) {
        console.log('Getting Bearer Token failed.');
    }
  }
}